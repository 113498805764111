import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
import BasicLayout from "../components/BasicLayout";
import bgImage from "assets/images/signin.jpg";
import { useConfig } from "../../../config";
import { toast } from "react-toastify";

function OtpVerification() {
    const { apiUrl } = useConfig();
    const location = useLocation();
    const navigate = useNavigate(); // To navigate after successful submission
    const signupData = location.state;
    const [otp, setOtp] = useState(["", "", "", ""]);
    const [resendTimer, setResendTimer] = useState(10); // Resend timer
    const [isSubmitting, setIsSubmitting] = useState(false); // Tracks the submission state
    const otpRefs = useRef([]);

    const email = signupData?.email // Get email from state or use a default

    // Handle OTP input changes and move to next field
    const handleOtpChange = (index, value) => {
        if (/^[0-9]$/.test(value)) {
            // Only allow single digit numbers
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            // Move to the next input if there is a next one
            if (index < otp.length - 1 && value) {
                otpRefs.current[index + 1].focus();
            }
        }
    };

    // Handle keydown event for backspace or delete
    const handleKeyDown = (index, event) => {
        if (event.key === "Backspace" || event.key === "Delete") {
            if (!otp[index]) {
                // If current box is empty, move to the previous one
                if (index > 0) {
                    otpRefs.current[index - 1].focus();
                }
            }
            const newOtp = [...otp];
            newOtp[index] = ""; // Clear the current box
            setOtp(newOtp);
        }
    };

    const handleOtpSubmit = async () => {
        const enteredOtp = otp.join("");
        setIsSubmitting(true); // Set submission state to true to disable button

        try {
            let body = {
                emailId: signupData.email, // Changed email to emailId
                password: signupData.password,
                otp: enteredOtp,
                businessName: signupData.businessName,
                supportApp: signupData.supportApp,
            };
            const response = await fetch(`${apiUrl}/user/signup`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
            });
            const res = await response.json();
            if (res.responseCode !== 200) {
                toast.error(res.responseMessage, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                });
                setIsSubmitting(false); // Reset submitting state on error
                return;
            }

            toast.success(res.responseData, {
                position: "top-right",
                autoClose: 2000, // Auto close the toast after 3 seconds
                hideProgressBar: false,
            });
            navigate("/authentication/sign-in"); // Redirect to sign-in page on success
        } catch (error) {
            // Display an error toast
            toast.error("An error occurred. Please try again later.", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
            });
            setIsSubmitting(false); // Reset submitting state on error
        }
    };

    const handleResendOtp = async () => {
        try {
            if (resendTimer === 0) {
                setOtp(["", "", "", ""]);
                otpRefs.current[0].focus();
                setResendTimer(10); // Reset the resend timer
                const response = await fetch(`${apiUrl}/user/send-otp?email=${signupData.email}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const res = await response.json();
                if (res.responseCode !== 200) {
                    toast.error(res.responseMessage, {
                        position: "top-right",
                        autoClose: 2000, // Auto close the toast after 3 seconds
                        hideProgressBar: false,
                    });
                    return;
                }

                toast.success(res.responseData, {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
            }
        } catch (error) {
            // Display an error toast
            toast.error('An error occurred. Please try again later.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
            });
        }


    };

    // Timer logic
    useEffect(() => {
        const timerInterval = setInterval(() => {
            setResendTimer((prev) => (prev > 0 ? prev - 1 : 0));
        }, 1000);
        return () => clearInterval(timerInterval); // Clear timer on component unmount
    }, []);

    // Check if all OTP digits are entered
    const isOtpComplete = otp.every((digit) => digit !== "");

    return (
        <BasicLayout image={bgImage}>
            <MDBox display="flex" justifyContent="center" alignItems="center" height="100vh" px={2}>
                <Card
                    sx={{
                        padding: { xs: "20px", sm: "30px", md: "50px" }, // Responsive padding
                        borderRadius: "20px", // Rounded corners
                        boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
                        width: "100%", // Full width for responsiveness
                        maxWidth: "500px", // Max width constraint
                    }}
                >
                    <MDBox pt={2} pb={3} px={3} textAlign="center">
                        <MDTypography
                            variant="h4"
                            fontWeight="bold"
                            mb={2}
                            color="textPrimary"
                            sx={{ fontSize: { xs: "1.5rem", md: "2rem" }, letterSpacing: "0.5px" }} // Responsive typography
                        >
                            OTP Verification
                        </MDTypography>
                        <MDTypography variant="body1" mb={4} color="textSecondary" sx={{ fontSize: { xs: "0.9rem", md: "1.2rem" } }}>
                            One Time Password (OTP) has been sent to <b>{email}</b>. <br />
                            Enter the OTP below to verify.
                        </MDTypography>

                        {/* Modern OTP Input Boxes in a Single Row */}
                        <MDBox
                            display="flex"
                            justifyContent="center"
                            gap={2}
                            sx={{
                                flexWrap: "nowrap", // Ensure inputs stay in a single row
                            }}
                        >
                            {otp.map((value, index) => (
                                <MDInput
                                    key={index}
                                    type="text"
                                    value={value}
                                    onChange={(e) => handleOtpChange(index, e.target.value)}
                                    onKeyDown={(e) => handleKeyDown(index, e)}
                                    inputRef={(el) => (otpRefs.current[index] = el)} // Store refs for each input
                                    inputProps={{
                                        maxLength: 1,
                                        style: {
                                            textAlign: "center",
                                            fontSize: { xs: "1.5rem", md: "2rem" }, // Adjust font size for mobile
                                            width: { xs: "45px", md: "60px" }, // Responsive width for OTP inputs
                                            height: { xs: "45px", md: "60px" }, // Responsive height for OTP inputs
                                            borderRadius: "10px",
                                            outline: "none", // Removing the default blue outline
                                        },
                                    }}
                                    sx={{
                                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Adding a slight shadow for a modern floating effect
                                        backgroundColor: "#f9f9f9", // Slightly off-white background for inputs
                                        "& input": {
                                            padding: "10px", // Padding for a more comfortable input
                                            color: "#333", // Dark gray color for input text
                                        },
                                        "&:hover": {
                                            borderColor: "#000", // Border darkens on hover
                                        },
                                        "&:focus-within": {
                                            borderColor: "#000", // Border becomes black on focus
                                            backgroundColor: "#fff", // White background on focus
                                            outline: "none", // Removing the blue outline on focus
                                        },
                                    }}
                                />
                            ))}
                        </MDBox>

                        {/* Message with Resend Timer */}
                        <MDTypography variant="body2" color="textSecondary" mt={3} sx={{ fontSize: { xs: "0.8rem", md: "1rem" } }}>
                            {resendTimer > 0 ? (
                                <>You can resend the OTP in 00:{resendTimer < 10 ? `0${resendTimer}` : resendTimer}</>
                            ) : (
                                <>
                                    Didn&apos;t receive the OTP?&nbsp;
                                    <MDTypography
                                        component="span"
                                        variant="body2"
                                        fontWeight="bold"
                                        color="primary"
                                        onClick={handleResendOtp}
                                        sx={{ cursor: "pointer", fontSize: { xs: "0.9rem", md: "1rem" } }} // Responsive font size for the link
                                    >
                                        Resend OTP
                                    </MDTypography>
                                </>
                            )}
                        </MDTypography>

                        {/* Verify OTP Button */}
                        <MDButton
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handleOtpSubmit}
                            disabled={!isOtpComplete || isSubmitting} // Disable button if OTP isn't complete or submitting
                            sx={{
                                padding: { xs: "10px", md: "14px" }, // Adjust padding for mobile screens
                                fontSize: { xs: "1rem", md: "1.2rem" }, // Responsive font size
                                marginTop: "20px",
                                background: "#1976d2", // Blue background for button
                                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", // Light shadow for depth
                                "&:hover": {
                                    backgroundColor: "#125ea3", // Keep blue color even during submission
                                    boxShadow: "0px 6px 30px rgba(0, 0, 0, 0.15)", // More intense shadow on hover
                                },
                                "&:disabled": {
                                    backgroundColor: "#1976d2", // Maintain blue color when disabled
                                    opacity: 0.7, // Slightly reduce opacity when disabled
                                    cursor: "not-allowed", // Change cursor to not-allowed when disabled
                                },
                            }}
                        >
                            {isSubmitting ? "Verifying..." : "Verify OTP"} {/* Change text when submitting */}
                        </MDButton>
                    </MDBox>
                </Card>
            </MDBox>
        </BasicLayout>
    );
}

export default OtpVerification;
