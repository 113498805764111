import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React, { useState, useEffect } from 'react'
import DefaultProjectCard from 'examples/Cards/ProjectCards/DefaultProjectCard'
import ComplexStatisticsCard from 'examples/Cards/StatisticsCards/ComplexStatisticsCard'
import { Grid } from '@mui/material'
import MDBox from 'components/MDBox'
import { useMaterialUIController } from 'context'
import { useConfig } from "../../config"
import { toast } from "react-toastify"
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import BeatLoader from "react-spinners/BeatLoader";
import MDTypography from 'components/MDTypography'
import SettlementLogs from "layouts/settlements/settlementLogs"
import formatTimeAndDate from "util/formatTimeAndDate";
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import logo from "assets/images/payhub.png"
import { capitalizeFirstLetter } from 'util/formatTimeAndDate'
import Loader from 'util/Loader'
import { useSandbox } from 'ZustandState/useSandbox'
import { Formik, Form, Field } from 'formik'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
function Settlement() {
    const { apiUrl } = useConfig()
    const sandbox = useSandbox((state) => state.sandbox)
    const userEmail = localStorage.getItem('user_email')
    const token = localStorage.getItem('user_token')
    const userApiKey = localStorage.getItem('user_apiKey')
    const [controller] = useMaterialUIController()
    const [loading, setLoading] = useState(false)
    const [allDataLoading, setAllDataLoading] = useState(true)
    const [page, setPage] = useState(0)
    const [settlementLogs, setSettlementLogs] = useState([])
    const [csvCompleteData, setCsvCompleteData] = useState([])
    const {
        darkMode
    } = controller
    const [data, setData] = useState()
    const initialDateRangeValues = {
        emailId: userEmail,
        start_date: '',
        end_date: ''
    }
    async function getProfile(body) {
        try {
            const response = await fetch(`${apiUrl}/user/getprofile`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'token': token,
                    'apiKey': userApiKey || ''
                },
                body: JSON.stringify(body)
            });
            const res = await response.json();
            if (res.responseCode === 403) {
                toast.error(res?.responseMessage, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                });
                localStorage.removeItem('user_token');
                navigate('/authentication/sign-in');
            }
            if (res.responseCode !== 200) {
                //console.log('unable to get user')
                return;
            }
            if (res?.responseData) {
                setData(res?.responseData)
            }
        } catch (e) {
            console.log(e)
        }
    }
    const getSettlementLogs = async () => {
        try {
            let body = {
                emailId: userEmail,
                limit: 10,
                skip: page * 10
            }
            if (!body.emailId) {
                return;
            }
            const response = await fetch(`${apiUrl}/user/getallusersettlements`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                    'token': token,
                    'apiKey': userApiKey || ''
                },
                body: JSON.stringify(body)
            })

            if (!response) return;
            const res = await response.json()
            if (res.responseCode !== 200) return;
            setSettlementLogs(res?.responseData)
        } catch (err) {
            console.log("Error Fetching Settlements: ", err)
        }
    }
    const getSettlementLogsForCsv = async (body) => {
        try {
            if (!body.emailId) {
                return;
            }
            const response = await fetch(`${apiUrl}/user/getallusersettlements`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                    'token': token,
                    'apiKey': userApiKey || ''
                },
                body: JSON.stringify(body)
            })

            if (!response) return;
            const res = await response.json()
            if (res.responseCode !== 200) return;
            return res?.responseData
        } catch (err) {
            console.log("Error Fetching Settlements: ", err)
        }
    }
    const downloadSettlementLogs = async () => {
        setLoading(true)
        setCsvCompleteData([])
        let csvPage = 0
        let responseData = true
        do {
            let body = {
                emailId: userEmail || '',
                limit: 500,
                skip: csvPage * 500,
            }

            await getSettlementLogsForCsv(body).then((res) => {
                if (res.length === 0) responseData = false;
                csvCompleteData.push(...res)
            })
            csvPage++
        } while (responseData)

        // Create the CSV content
        const csvContent = [
            ["Time", "Date", "Gross Volume", "Net Fees", "Bank Fees", "Net Gst", "Chargeback", "Rolling Reserve", "Misc", "Refund", "Net Volume", "USDT Rate", "USDT Net", "Remarks"],
            ...csvCompleteData.map(({ transaction_date, amount, feeCharged, netBankFees, netGst, chargeback, rolling_reserve, misc, refund, amountSettled, usdtRate, usdt, ref_no }) => [
                formatTimeAndDate(transaction_date).formattedTime || '',
                formatTimeAndDate(transaction_date).formattedDate || '',
                amount,
                feeCharged?.toFixed(2),
                netBankFees?.toFixed(2),
                netGst?.toFixed(2),
                chargeback,
                rolling_reserve,
                misc,
                refund,
                amountSettled?.toFixed(2),
                usdtRate,
                usdt?.toFixed(2),
                ref_no || '',
            ]),
        ].map(row => row.join(',')).join('\n');

        // Create a Blob containing the CSV data
        const blob = new Blob([csvContent], { type: 'text/csv' });

        // Create a download link
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'settlement.csv';

        // Programmatically click the link to trigger the download
        a.click();

        // Clean up
        URL.revokeObjectURL(url);
        setLoading(false)
    }
    const handleDateRangeSubmit = async (values, { setSubmitting }) => {
        try {
            if (values?.start_date === "" || values?.end_date === "" || new Date(values.end_date) < new Date(values.start_date)) {
                toast.error("Invalid Date Range", {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 2 seconds
                    hideProgressBar: false,
                });
                return;
            }
            const response = await fetch(`${apiUrl}/user/downloadUserSettlementsCsvByDate`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                    'token': token,
                    'apiKey': userApiKey || ''
                },
                body: JSON.stringify(values)
            })
            const res = await response.json()
            if (res.responseCode !== 200) {
                toast.error(res.responseMessage, {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                return;
            }
            const a = document.createElement('a');
            a.href = res?.responseData;
            a.download = 'settlement.csv';
            a.click(); // Programmatically click the link to trigger the download
            URL.revokeObjectURL(res?.responseData);  // Clean up
        } catch (err) {
            console.log("Error Fetching handleDateRangeSubmit: ", err)
        }
    }

    // const downloadPdfInvoice = async (log) => {
    //     const pdfDoc = await PDFDocument.create();
    //     // Set page size to A4
    //     const page = pdfDoc.addPage([595, 842]); // 595 x 842 are the dimensions for A4 in points
    //     // Add left border Line
    //     page.drawLine({ start: { x: 8, y: 830 }, end: { x: 8, y: 12 }, thickness: 1, color: rgb(0, 0, 0) });
    //     // Add right border Line
    //     page.drawLine({ start: { x: 587, y: 830 }, end: { x: 587, y: 12 }, thickness: 1, color: rgb(0, 0, 0) });
    //     // Add upper border Line
    //     page.drawLine({ start: { x: 8, y: 830 }, end: { x: 587, y: 830 }, thickness: 1, color: rgb(0, 0, 0) });
    //     // Add bottom border Line
    //     page.drawLine({ start: { x: 8, y: 12 }, end: { x: 587, y: 12 }, thickness: 1, color: rgb(0, 0, 0) });

    //     // Add Company Logo
    //     const logoUrl = logo; // Replace with the actual path or URL to your logo
    //     const logoImageBytes = await fetch(logoUrl).then(res => res.arrayBuffer());
    //     const logoImage = await pdfDoc.embedPng(logoImageBytes);
    //     page.drawImage(logoImage, { x: 380, y: 770, width: 200, height: 40 });

    //     // Add Merchant Information
    //     const merchantInfo = [
    //         { title: 'Merchant Name', value: capitalizeFirstLetter(data?.business_name) },
    //         { title: 'Settlement Date', value: log.transaction_date.split('T')[0] || '' },
    //         { title: 'Settlement No.', value: (log.txIndex + 1).toString() },
    //     ];

    //     merchantInfo.forEach(({ title, value }, index) => {
    //         page.drawText(`${title}:`, { x: 50, y: 700 - index * 30, size: 18, color: rgb(0, 0, 0) });
    //         page.drawText(value, { x: 350, y: 700 - index * 30, size: 18, color: rgb(0, 0, 0) });
    //     });

    //     // Add Settlement Report
    //     page.drawRectangle({ x: 50, y: 580, width: 500, height: 30, color: rgb(0, 0, 0) });
    //     page.drawText('Settlement Report', { x: 200, y: 587, fontSize: 11, color: rgb(1, 1, 1) });

    //     const feeCharged = log?.feeCharged;
    //     const feeChargedValue = feeCharged !== undefined ? feeCharged.toFixed(2).toString() : '';

    //     // Add Data Section
    //     const dataSection = [
    //         { title: 'Merchant Volume', value: (log.amount || '').toString() },
    //         { title: 'Gateway Fees', value: (log?.feePercentage && log?.feePercentage + '%' || '').toString() },
    //         { title: 'Net Gateway Fees', value: feeChargedValue },
    //     ];

    //     dataSection.forEach(({ title, value }, index) => {
    //         page.drawText(title, { x: 50, y: 530 - index * 35, size: 15, color: rgb(0, 0, 0) });
    //         page.drawText(value, { x: 400, y: 530 - index * 35, size: 15, color: rgb(0, 0, 0) });
    //     });

    //     // Add Footer Line
    //     page.drawLine({ start: { x: 50, y: 180 }, end: { x: 550, y: 180 }, thickness: 1, color: rgb(0, 0, 0) });

    //     // Add Footer Data
    //     const footerData = [
    //         { title: 'Merchant Net', value: (log.amountSettled || '').toString() },
    //         { title: 'USDT Rate', value: (log?.usdtRate && log?.usdtRate || '')?.toString() },
    //         { title: 'USDT Net Settlement', value: (log?.usdt?.toFixed(0)).toString() },
    //     ];

    //     footerData.forEach(({ title, value }, index) => {
    //         page.drawText(title, { x: 50, y: 150 - index * 30, size: 15, color: rgb(0, 0, 0) });
    //         page.drawText(value, { x: 400, y: 150 - index * 30, size: 15, color: rgb(0, 0, 0) });
    //     });
    //     let filename = `invoice,${capitalizeFirstLetter(data?.business_name)} ${formatTimeAndDate(log.transaction_date).formattedDate}.pdf`
    //     // Save the PDF
    //     const pdfBytes = await pdfDoc.save();

    //     // Trigger download
    //     const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    //     const link = document.createElement('a');
    //     link.href = window.URL.createObjectURL(blob);
    //     link.download = filename;
    //     link.click();
    // }
    const createPDF = async (log) => {
        const pdfDoc = await PDFDocument.create();

        // Add a new page with full A4 dimensions
        const page = pdfDoc.addPage([595, 842]);

        // Load fonts
        const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
        const regularFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

        // Embed logo image
        const logoUrl = logo; // Assuming `logo` is defined elsewhere with the actual URL or path to the logo
        const logoImageBytes = await fetch(logoUrl).then((res) => res.arrayBuffer());
        const logoImage = await pdfDoc.embedPng(logoImageBytes);

        // Draw the Payhub logo at the top
        page.drawImage(logoImage, {
            x: 50,
            y: 760,
            width: 140,
            height: 30,
        });

        // Header - Draw title below the logo
        page.drawText(`Settlement Report - ${log.transaction_date.split('T')[0]}`, {
            x: 50,
            y: 720,
            size: 32,
            font: boldFont,
            color: rgb(0, 0, 0),
        });

        // Draw the separator line below the title
        page.drawLine({
            start: { x: 50, y: 710 },
            end: { x: 545, y: 710 },
            thickness: 2,
            color: rgb(0.1, 0.2, 0.7),
        });

        // Greeting and introduction text
        page.drawText('Hello from Payhub!', { x: 50, y: 680, size: 12, font: boldFont, color: rgb(0, 0, 0) });
        page.drawText('Your daily settlement report is ready!', { x: 50, y: 665, size: 12, font: regularFont });
        page.drawText('The transaction details are enclosed for your reference.', { x: 50, y: 650, size: 12, font: regularFont });

        // Merchant name, Settlement Date, and Settlement No.
        page.drawText(`Merchant - ${capitalizeFirstLetter(data?.business_name)}`, {
            x: 50,
            y: 630,
            size: 12,
            font: regularFont,
            color: rgb(0, 0, 0),
        });
        page.drawText(`Settlement Date - ${log.transaction_date.split('T')[0]}`, {
            x: 50,
            y: 615,
            size: 12,
            font: regularFont,
        });
        page.drawText(`Settlement No. - ${(log.txIndex + 1)}`, {
            x: 50,
            y: 600,
            size: 12,
            font: regularFont,
        });

        // Define right boundaries for columns that need right alignment
        const rateColumnRightBoundary = 350; // Adjusted to shift left
        const amountColumnRightBoundary = 545;

        // Table header with updated column name and adjusted positions
        page.drawText('Particulars', { x: 50, y: 570, size: 12, font: boldFont, color: rgb(0, 0, 0) });
        page.drawText('Currency', { x: 220, y: 570, size: 12, font: boldFont, color: rgb(0, 0, 0) });
        page.drawText('Rate (%)', { x: rateColumnRightBoundary - 40, y: 570, size: 12, font: boldFont, color: rgb(0, 0, 0) });
        page.drawText('Type', { x: 400, y: 570, size: 12, font: boldFont, color: rgb(0, 0, 0) });
        page.drawText('Amount', { x: amountColumnRightBoundary - 50, y: 570, size: 12, font: boldFont, color: rgb(0, 0, 0), align: 'right' });

        // Draw line below header
        page.drawLine({
            start: { x: 50, y: 565 },
            end: { x: 545, y: 565 },
            thickness: 1,
            color: rgb(0.1, 0.2, 0.7),
        });

        // Table data with percentage and type (credit/debit) information
        const tableData = [
            { particulars: 'Total Amount', currency: 'INR', amount: log.amount.toFixed(2).toString(), rate: '', type: 'Credit' },
            { particulars: 'Platform Fee', currency: 'INR', amount: log?.feeCharged?.toFixed(2).toString() || '0.00', rate: log?.feePercentage?.toString() || '', type: 'Debit' },
            { particulars: 'Bank Fee', currency: 'INR', amount: log?.netBankFees?.toFixed(2).toString() || '0.00', rate: log?.bankFees?.toString() || '', type: 'Debit' },
            { particulars: 'GST', currency: 'INR', amount: log?.netGst?.toFixed(2).toString() || '0.00', rate: log?.gst?.toString() || '', type: 'Debit' },
            { particulars: 'Total Chargeback Amount', currency: 'INR', amount: log?.chargeback?.toFixed(2)?.toString() || '0.00', rate: '', type: 'Debit' },
            { particulars: 'MISC Debit', currency: 'INR', amount: log?.misc?.toFixed(2)?.toString() || '0.00', rate: '', type: 'Debit' },
            { particulars: 'Rolling Reserve', currency: 'INR', amount: log?.rolling_reserve?.toFixed(2)?.toString() || '0.00', rate: '', type: 'Debit' },
            { particulars: 'Total Refund Amount', currency: 'INR', amount: log?.refund?.toFixed(2).toString() || '0.00', rate: '', type: 'Credit' },
            { separator: true }, // Marker to indicate a line after this item
            { particulars: 'Net Amount', currency: 'INR', amount: log?.amountSettled?.toFixed(2).toString() || '0.00', rate: '', type: 'Credit' },
        ];


        // Draw table rows with consistent spacing, adjusted column positions, and right-aligned Rate and Amount columns
        let yPosition = 540;
        tableData.forEach((item) => {
            if (item.separator) {
                // Draw line to separate MISC Debit and Net Amount
                yPosition -= 10;
                page.drawLine({
                    start: { x: 50, y: yPosition },
                    end: { x: 545, y: yPosition },
                    thickness: 1,
                    color: rgb(0.1, 0.2, 0.7),
                });
                yPosition -= 20; // Move position down for the next row after the line
                return; // Skip rendering text for this separator row
            }

            // Draw text for each item with adjusted column positions
            page.drawText(item.particulars, { x: 50, y: yPosition, size: 12, font: regularFont, color: rgb(0, 0, 0) });
            page.drawText(item.currency, { x: 220, y: yPosition, size: 12, font: regularFont, color: rgb(0, 0, 0) });

            // Right-align Rate (%) column with adjusted position
            const rateTextWidth = regularFont.widthOfTextAtSize(item.rate, 12);
            page.drawText(item.rate, {
                x: rateColumnRightBoundary - rateTextWidth,
                y: yPosition,
                size: 12,
                font: regularFont,
                color: rgb(0, 0, 0),
            });

            page.drawText(item.type, { x: 400, y: yPosition, size: 12, font: regularFont, color: rgb(0, 0, 0) });

            // Right-align Amount column
            const amountTextWidth = regularFont.widthOfTextAtSize(item.amount, 12);
            page.drawText(item.amount, {
                x: amountColumnRightBoundary - amountTextWidth, // Right-align to boundary
                y: yPosition,
                size: 12,
                font: regularFont,
                color: rgb(0, 0, 0),
            });

            yPosition -= 20;
        });


        // USDT Conversion Summary Section
        yPosition -= 30;  // Add extra space between the main table and the USDT summary
        page.drawText('USDT Conversion Summary', { x: 50, y: yPosition, size: 14, font: boldFont, color: rgb(0, 0, 1) });
        yPosition -= 20;
        page.drawText(`USDT Rate: ${log?.usdtRate?.toString() || 'N/A'} INR`, { x: 50, y: yPosition, size: 12, font: regularFont, color: rgb(0.2, 0.2, 0.2) });
        yPosition -= 20;
        page.drawText(`Equivalent in USDT: ${log?.usdt?.toFixed(2) || 'N/A'}`, { x: 50, y: yPosition, size: 12, font: regularFont, color: rgb(0.2, 0.2, 0.2) });

        // Remarks Section
        yPosition -= 30; // Space between USDT Summary and Remarks section
        page.drawText('Remarks', { x: 50, y: yPosition, size: 14, font: boldFont, color: rgb(0, 0, 1) });
        yPosition -= 20;
        page.drawText(log?.ref_no || 'No remarks provided.', { x: 50, y: yPosition, size: 12, font: regularFont, color: rgb(0.2, 0.2, 0.2) });

        // Additional space to ensure the footer has enough separation
        yPosition -= 30;

        // Footer section with links and contact
        page.drawLine({
            start: { x: 50, y: yPosition + 10 }, // Add line above footer
            end: { x: 545, y: yPosition + 10 },
            thickness: 1,
            color: rgb(0.1, 0.2, 0.7),
        });

        yPosition -= 20; // Positioning footer content below the line
        page.drawText('Got questions? FAQ or get in touch with one of our experts here.', { x: 50, y: yPosition, size: 10, font: regularFont, color: rgb(0, 0, 1) });
        yPosition -= 15;
        page.drawText('Thank you!\nTeam Payhub', { x: 50, y: yPosition - 20, size: 10, font: regularFont, color: rgb(0, 0, 0) });



        // Save and download the PDF
        const pdfBytes = await pdfDoc.save();
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `invoice,${capitalizeFirstLetter(data?.business_name)} ${formatTimeAndDate(log.transaction_date).formattedDate}.pdf`;
        link.click();
    };
    useEffect(() => {
        let body = {
            emailId: userEmail
        }
        getProfile(body)
    }, [sandbox])
    useEffect(() => {
        getSettlementLogs().then(() => {
            setAllDataLoading(false)
        })
    }, [page, sandbox])
    return (
        <DashboardLayout>
            <DashboardNavbar />
            {
                allDataLoading ? (<Loader />) : (
                    <MDBox py={3}>
                        <MDBox mt={1}>
                            {
                                data && <Grid display={'flex'} flexDirection={'row'} container spacing={3}>
                                    <Grid xl={5}>
                                        <DefaultProjectCard
                                            business={data?.business_name}
                                            email={data?.emailId}
                                            name={`${data?.first_name || ''} ${data?.last_name || ''}`}
                                        />
                                    </Grid>
                                    <Grid xl={7}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} lg={3.5} xl={3.5}>
                                                <MDBox mb={1.5}>
                                                    <ComplexStatisticsCard
                                                        color="7FDF9A"
                                                        title="Today's Volume"
                                                        count={Number(data?.last24hr).toFixed(0) || ''}
                                                    />
                                                </MDBox>
                                            </Grid>

                                            <Grid item xs={12} lg={3.5} xl={3.5}>
                                                <MDBox mb={1.5}>
                                                    <ComplexStatisticsCard
                                                        color="FFFED2"
                                                        title="Yesterday's Volume"
                                                        count={`${Number(data?.yesterday).toFixed(0) || ''}`}
                                                    />
                                                </MDBox>
                                            </Grid>
                                            <Grid item xs={12} lg={3.5} xl={3.5}>
                                                <MDBox mb={1.5}>
                                                    <ComplexStatisticsCard
                                                        color="C9DEDE"
                                                        title="Wallet Balance"
                                                        count={`${Number(data.balance).toFixed(0) || 0}`}
                                                    />
                                                </MDBox>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }

                            {/* settlementLogs */}
                            <MDBox mt={5} mx={0.5} ml={3}>
                                <Grid display={'flex'} flexDirection={'row'} container spacing={3}>
                                    <Grid xl={12}>
                                        <MDBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" py={1.5}>
                                            <MDBox display="flex" flexDirection="row" alignItems="center">
                                                <MDTypography variant="h6" color={darkMode ? "white" : "black"}>Settlement Logs</MDTypography>
                                                <MDBox pl={3}>
                                                    {
                                                        loading ? (<BeatLoader
                                                            color="#36d7b7"
                                                            cssOverride={{}}
                                                            size={10}
                                                        />) : (
                                                            <FileDownloadOutlinedIcon onClick={() => downloadSettlementLogs()} sx={{ cursor: "pointer", fontWeight: "bold" }} color={darkMode ? "white" : "black"} fontSize="medium" />
                                                        )
                                                    }
                                                </MDBox>
                                            </MDBox>

                                            {/* Right section: Date inputs and Download button */}
                                            <MDBox display="flex" flexDirection="row" alignItems="center">
                                                <Formik initialValues={initialDateRangeValues} onSubmit={handleDateRangeSubmit} >
                                                    {({ handleChange, values, isSubmitting }) => (
                                                        <Form>
                                                            <Grid container spacing={2} alignItems="center">
                                                                {/* Start Date Input */}
                                                                <Grid item xs={12} sm={6} md={4}>
                                                                    <Field
                                                                        as={MDInput}
                                                                        label="Start Date"
                                                                        size="large"
                                                                        fullWidth
                                                                        type="date"
                                                                        name="start_date"
                                                                        onChange={handleChange}
                                                                        value={values.start_date}
                                                                        variant="outlined"
                                                                        InputLabelProps={{ shrink: true }}
                                                                    />
                                                                </Grid>

                                                                {/* End Date Input */}
                                                                <Grid item xs={12} sm={6} md={4}>
                                                                    <Field
                                                                        as={MDInput}
                                                                        label="End Date"
                                                                        size="large"
                                                                        fullWidth
                                                                        type="date"
                                                                        name="end_date"
                                                                        onChange={handleChange}
                                                                        value={values.end_date}
                                                                        variant="outlined"
                                                                        InputLabelProps={{ shrink: true }}
                                                                    />
                                                                </Grid>

                                                                {/* Download Button */}
                                                                <Grid item xs={12} sm={12} md={4} display="flex" justifyContent={{ xs: "center", md: "flex-start" }}>
                                                                    <MDButton
                                                                        type="submit"
                                                                        variant="contained"
                                                                        color={isSubmitting ? "secondary" : "info"} // Change color when submitting
                                                                        sx={{
                                                                            height: "48px",
                                                                            minWidth: "150px",
                                                                            opacity: isSubmitting ? 0.5 : 1, // Fade button when submitting
                                                                            cursor: isSubmitting ? "not-allowed" : "pointer", // Show disabled cursor
                                                                        }}
                                                                        disabled={isSubmitting} // Disable button
                                                                    >
                                                                        {isSubmitting ? "Downloading..." : "Download"} {/* Change text */}
                                                                    </MDButton>
                                                                </Grid>

                                                            </Grid>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </MDBox>
                                        </MDBox>
                                    </Grid>
                                    <SettlementLogs pages={page} pageSetter={setPage} logs={settlementLogs} downloadInvoice={createPDF} />
                                </Grid>
                            </MDBox>
                        </MDBox>
                    </MDBox>
                )
            }

        </DashboardLayout>
    )
}

export default Settlement