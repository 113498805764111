import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "../components/BasicLayout";
import bgImage from "assets/images/signin.jpg";
import { useConfig } from "../../../config"
import { toast } from 'react-toastify';
import MenuItem from "@mui/material/MenuItem";
import { useMaterialUIController } from "context";
function SignUp() {
  const [controller] = useMaterialUIController()
  const { darkMode } = controller
  const { apiUrl } = useConfig()
  const navigate = useNavigate(); // To navigate to OTP verification page

  const validationSchema = Yup.object({
    businessName: Yup.string().required("Business name is required"),
    emailId: Yup.string().email("Invalid email format").required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/[0-9]/, "Password must contain at least one digit")
      .matches(/[@$!%*?&#]/, "Password must contain at least one special character")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
    supportApp: Yup.string()
      .oneOf(["whatsapp", "telegram"], "Please select a valid support app")
      .required("Support app is required"), // Ensure that supportApp is selected
  });



  const initialValues = {
    businessName: "",
    emailId: "",
    password: "",
    confirmPassword: "",
    supportApp: "whatsapp"
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await fetch(`${apiUrl}/user/send-otp?email=${values.emailId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const res = await response.json();
      if (res.responseCode !== 200) {
        toast.error(res.responseMessage, {
          position: "top-right",
          autoClose: 2000, // Auto close the toast after 3 seconds
          hideProgressBar: false,
        });
        return;
      }
      setSubmitting(false);
      navigate("/otp-verification", { state: { email: values.emailId, password: values.password, businessName: values.businessName, supportApp: values.supportApp } }); // Pass email to OTP page
      setSubmitting(false);
    } catch (error) {
      // Display an error toast
      toast.error('An error occurred. Please try again later.', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
      });

      // Reset submitting state
      setSubmitting(false);
    }

  };


  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Create Merchant Account
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, }) => (
              <Form >
                <MDBox mb={2}>
                  <Field type="text" label="Business Name" as={MDInput} fullWidth name="businessName" />
                  <MDTypography color="error" variant="caption">
                    <ErrorMessage name="businessName" component="div" />
                  </MDTypography>
                </MDBox>

                <MDBox mb={2}>
                  <Field type="email" label="Email" as={MDInput} fullWidth name="emailId" />
                  <MDTypography color="error" variant="caption">
                    <ErrorMessage name="emailId" component="div" />
                  </MDTypography>
                </MDBox>
                <MDBox mb={2}>
                  <Field type="password" label="Password" as={MDInput} fullWidth name="password" />
                  <MDTypography color="error" variant="caption">
                    <ErrorMessage name="password" component="div" />
                  </MDTypography>
                </MDBox>
                <MDBox mb={2}>
                  <Field type="password" label="Confirm Password" as={MDInput} fullWidth name="confirmPassword" />
                  <MDTypography color="error" variant="caption">
                    <ErrorMessage name="confirmPassword" component="div" />
                  </MDTypography>
                </MDBox>
                {/* Dropdown Field with Placeholder */}
                <MDBox mb={2}>
                  <Field
                    type="text"
                    select
                    as={MDInput}
                    fullWidth
                    name="supportApp"
                    label="Select your method of Tech Support" // Placeholder label
                    InputProps={{
                      classes: { root: darkMode ? "select-input-styles-darkMode" : "select-input-styles" },
                    }}
                  >
                    {/* Placeholder option */}

                    <MenuItem value="whatsapp">WhatsApp</MenuItem>
                    <MenuItem value="telegram">Telegram</MenuItem>
                  </Field>
                  <MDTypography color="error" variant="caption">
                    <ErrorMessage name="supportApp" component="div" />
                  </MDTypography>
                </MDBox>

                {/* <MDBox display="flex" alignItems="center" ml={-1}>
                <Checkbox />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                >
                  &nbsp;&nbsp;I agree the&nbsp;
                </MDTypography>
                <MDTypography component="a" href="#" variant="button" fontWeight="bold" color="info" textGradient>
                  Terms and Conditions
                </MDTypography>
              </MDBox> */}
                <MDBox mt={4} mb={1}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    type="submit"
                    disabled={isSubmitting} // Disable the button when isSubmitting is true
                  >
                    {isSubmitting ? "Processing..." : "Proceed"} {/* Change text when submitting */}
                  </MDButton>
                </MDBox>

              </Form>
            )}
          </Formik>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default SignUp;
