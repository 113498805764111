import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import Header from "layouts/profile/components/Header";
import { useEffect, useState } from "react";
import { useConfig } from "../../config"
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify'
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
function Overview() {
  const { apiUrl } = useConfig()
  const token = localStorage.getItem('user_token')
  const apiKey = localStorage.getItem('user_apiKey');
  const userEmail = localStorage.getItem('user_email')
  const navigate = useNavigate()
  const [user, setUser] = useState({
    emailId: '',
    apiKey: '',
    first_name: '',
    last_name: '',
    business_name: ''
  })
  async function getProfile(body) {
    try {
      const response = await fetch(`${apiUrl}/user/getprofile`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': token,
          'apiKey': apiKey || ''
        },
        body: JSON.stringify(body)
      });
      const res = await response.json();
      if (res.responseCode !== 200) {
        toast.error(res?.responseMessage, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
        });
        localStorage.removeItem('user_token');
        navigate('/authentication/sign-in');
        return
      }
      if (res?.responseData) {
        setUser(res.responseData)
      }
    } catch (e) {
      console.log("Error Fetching profile: ", e)
    }
  }


  useEffect(() => {
    const userBody = {
      emailId: userEmail
    }
    getProfile(userBody)
  }, [])
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={1} />
      <Header>
        <MDBox >
          <Grid container spacing={1}>
            {/* <Grid item xs={12} md={6} xl={4}>
              <PlatformSettings />
            </Grid> */}
            <Grid item xs={12} md={12} xl={12} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <ProfileInfoCard
                title="Basic information"
                info={{
                  Business: user ? user.business_name : '',
                  email: user ? user.emailId : "",
                  ...(user?.first_name && user?.last_name ? { Name: `${user.first_name} ${user.last_name}`.trim() } : {})
                  // Apikey: user ? user.apiKey : "",
                  // encryptionKey: encryptionKey || '',
                }}

                action={{ route: "", tooltip: "Edit Profile" }}
                shadow={false}
              />
              {/* <Divider orientation="vertical" sx={{ mx: 0 }} /> */}
            </Grid>

            {/* <Grid item xs={12} xl={4}>
              <ProfilesList title="conversations" profiles={profilesListData} shadow={false} />
            </Grid> */}
          </Grid>

        </MDBox>
      </Header>


    </DashboardLayout>
  );
}

export default Overview;
